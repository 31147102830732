export default function GoogleIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8502 7.468C16.9522 8.014 17.0082 8.586 17.0082 9.184C17.0082 13.852 13.8832 17.172 9.16321 17.172C4.64821 17.172 0.991211 13.515 0.991211 9C0.991211 4.485 4.64821 0.827999 9.16321 0.827999C11.3702 0.827999 13.2132 1.64 14.6282 2.958L12.3242 5.262V5.257C11.4662 4.44 10.3782 4.021 9.16321 4.021C6.46621 4.021 4.27521 6.299 4.27521 8.996C4.27521 11.692 6.46621 13.976 9.16321 13.976C11.6092 13.976 13.2742 12.576 13.6172 10.656H9.16321V7.469L16.8502 7.468Z"
        fill="currentColor"
      />
    </svg>
  )
}
